<template>
  <v-container style="text-align: center; justify-content: center;" :style="enableMargins ? '' : 'max-width: 100% !important;'">
    <v-row>
      <v-col cols="6" style="text-align: right;"><h1>PROVA SELETIVA #2</h1></v-col>
      <v-col cols="6" style="text-align: left;"><v-checkbox v-model="enableMargins" label="Habilitar Margens" color="accent"/></v-col>
      <v-col cols="12" class="pt-0 d-flex justify-center" style="width: 10rem;">
        <div style="width: 500px; text-align: justify;">Transcreva as questões do PDF fornecido para a estrutura abaixo. Em caso de dúvida, pode consultar o <b>vídeo</b> e o <b>manual</b>, que estão no rodapé desta página.
        <a href="https://drive.google.com/file/d/1V7CcEnKRSQRRUJWI0Tt-b4DY4XYkcQM0/view?usp=sharing"
           style="color: #7E5A9B !important;" target="_blank">Clique aqui para baixar o PDF da prova!</a>
        </div>
      </v-col>
    </v-row>
    <v-row :class="enableMargins ? 'row-margin' : ''">
      <v-col :class="enableMargins ? 'card-margin' : ''">
        <v-card>
          <v-card-title>Questões</v-card-title>
          <v-card-text>
            <v-form ref="addQForm" v-model="addQValid">
              <v-row dense style="overflow: hidden;">
                <v-col cols="3">
                  <v-select v-model="questionAddType" label="Tipo de Questão" color="accent" item-color="accent"
                            :items="['Objetiva', 'Discursiva', 'Certo/Errado']" :rules="[v => (!!v)]"
                            @change="questionAddType==='Objetiva'?questionAddNumberItems=5:questionAddNumberItems=0"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="questionAddNumber" label="Número de Questões" color="accent"
                                :rules="[v => (v>0 && !isNaN(v))]" type="number" autocomplete="off"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.number="questionAddNumberItems" :disabled="questionAddType==='Certo/Errado'"
                                :label="questionAddType==='Objetiva'?'Alternativas por questão':'Número de Itens por questão'"
                                color="accent" :rules="[v => (v===0 || (v>0 && !isNaN(v) && v<27))]"
                                type="number" autocomplete="off"></v-text-field>
                </v-col>
                <v-col cols="3" style="align-self: center;">
                  <v-btn small depressed class="ignored-button" color="accent" @click="addQuestionsStart"
                         style="width: 49%; font-size: x-small; margin-right: 1%;" :disabled="!addQValid">+ No Início</v-btn>
                  <v-btn small depressed class="ignored-button" color="accent" @click="addQuestionsEnd"
                         style="width: 49%; font-size: x-small; margin-left:  1%;" :disabled="!addQValid">+ No Final</v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-form ref="removeQForm" v-model="removeQValid">
              <v-row dense style="overflow: hidden;">
                <v-col cols="5">
                  <v-text-field v-model.number="questionRemoveNumber" label="Número de Questões para Remoção"
                                :rules="[v => (v>0 && !isNaN(v) && v<=questionNumber)]" color="accent"
                                type="number" autocomplete="off"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="questionRemoveOrder" label="Posição" color="accent"
                            :items="['Do início', 'Do final']" :rules="[v => (!!v)]"></v-select>
                </v-col>
                <v-col cols="3" style="align-self: center;">
                  <v-btn block small depressed color="error" @click="RemoveQuestions"
                         class="ignored-button" style="font-size: small;" :disabled="!removeQValid">Remover</v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row dense v-if="questionSummary.length">
              <v-col cols="12" style="text-align: left;" class="text--primary">
                <span><b>Resumo: </b>{{questionSummary}}</span><br>
                <span><b>Atalhos de teclado: </b>CTRL + Q = Alternar modo de edição</span>
              </v-col>
            </v-row>
            <v-row dense justify="center" align="stretch" v-for="(question, index) in Questions" :key="question.keyId">
              <v-col cols="12" style="position: relative">
                <v-btn style="position: absolute; right: 0; top: 0;" icon @click="toggleSingleEdit(index)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <edit-question-submission :question-meta="question" :exam-meta="examMeta" :question-number="index"
                                          :edit-mode="isEditing[index]" :external-gabarito="externalGabaritos[index]"
                                          @toggle-disc="changeQuestionType(index, ...arguments)" @toggle-ce="changeCEFlag(index, ...arguments)"
                                          @set-item="setNumItems(index, ...arguments)" @increment-item="changeNumItems(index, ...arguments)"
                                          @question-mounted="setQuestionUploadFunction" @submit-q-object="getQuestionObject"
                                          style="height: 99%; text-align: initial;" :style="enableMargins ? 'width: 90%' : 'width: calc(100% - 40px)'"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row :class="enableMargins ? 'row-margin' : ''">
      <v-col :class="enableMargins ? 'card-margin' : ''">
        <v-card>
          <v-card-title>Gabarito</v-card-title>
          <v-card-text>
            <v-form ref="gabaritosForm" v-model="gabaritosValid">
              <v-row dense>
                <v-col cols="4" xl="2" lg="2" md="3" sm="3" v-for="(question, qIdx) in Questions" :key="question.keyId">
                  <v-text-field v-model="externalGabaritos[qIdx]" @input="setGabarito(qIdx)" :disabled="question.disc"
                                :rules="[v => (!v||(!!v && v.length===2 && alphabet.slice(0, question.isCE?2:question.numItems).includes(v)))]"
                                :label="`Q${qIdx+1}`+(question.disc?' - Discursiva':'')" solo color="accent"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row :class="enableMargins ? 'row-margin' : ''">
      <v-col :class="enableMargins ? 'card-margin' : ''">
        <v-card>
          <v-card-title>Submissão</v-card-title>
          <v-card-text>
            <v-btn large color="accent" @click="createExam" :disabled="!Questions.length || !gabaritosValid || isUploading">Submeter</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditQuestionSubmission from '@/components/EditQuestionSubmission';
export default {
  name: 'SkillTest',
  components: {EditQuestionSubmission},
  data: () => ({
    enableMargins: true,
    addQValid: false, removeQValid: false, gabaritosValid: false,
    Questions: [],
    questionNumber: 0,
    isEditing: [],
    currentKeyId: 0, // Solves problem https://deepsource.io/blog/key-attribute-vue-js/
    questionUploadFunctions: [],
    questionObjects: {},

    questionAddType: null,
    questionAddNumber: null,
    questionAddNumberItems: null,
    questionRemoveNumber: null,
    questionRemoveOrder: null,

    externalGabaritos: [],

    isUploading: false,
    alphabet: ['a)', 'b)', 'c)', 'd)', 'e)', 'f)', 'g)', 'h)', 'i)', 'j)', 'k)', 'l)', 'm)', 'n)',
      'o)', 'p)', 'q)', 'r)', 's)', 't)', 'u)', 'v)', 'w)', 'x)', 'y)', 'z)']
  }),
  computed: {
    examMeta() { return { institution: 'PROVA SELETIVA', year: '2022', subject: this.$store.state.auth.user.displayName } },
    questionSummary() {
      let isLastQuestionDisc = null; let summary = []; let groupIndex = 0;
      for (let q of this.Questions) {
        const qType = q.disc?'Discursivas':'Objetivas';
        if (q.disc !== isLastQuestionDisc) { groupIndex += 1; summary.push([1, qType]); isLastQuestionDisc = q.disc; }
        else { summary[groupIndex-1] = [summary[groupIndex-1][0] + 1, qType] }
      } return summary.map(group => group.join(' ')).join(' + ');
    }
  },
  watch: { Subject: function(){this.subjectComplement = null}, Questions: function(qArr){this.isEditing = Array(qArr.length).fill(true) } },
  mounted() {
    this._listenEditMode = (e) => { if (e.key === "q" && (e.ctrlKey || e.metaKey)) { e.preventDefault(); this.toggleEditMode(); } };
    document.addEventListener('keydown', this._listenEditMode.bind(this));
  },
  beforeDestroy() { document.removeEventListener('keydown', this._listenEditMode); },
  methods: {
    setGabarito(i) { if (this.externalGabaritos[i].length === 1) { this.externalGabaritos[i] += ')'} else { this.externalGabaritos[i] = null } },
    changeNumItems(i, val) { this.Questions[i].numItems += val; },
    setNumItems(i, val) { this.Questions[i].numItems = val; },
    changeCEFlag(i, val) { this.Questions[i].isCE = val; },
    changeQuestionType(i, val) { this.Questions[i].disc = val; },
    toggleSingleEdit(i) {
      const oldVal = this.isEditing[i]; this.$set(this.isEditing, i, !oldVal);
      if (oldVal && ('MathJax' in window)) { this.$nextTick(function() { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.body]) }) }
    },
    toggleEditMode() {
      if (this.isEditing[0]) {
        this.isEditing = Array(this.Questions.length).fill(false);
        if ('MathJax' in window) { this.$nextTick(function() { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.body]) }) }
      } else{ this.isEditing = Array(this.Questions.length).fill(true); }
    },
    setQuestionUploadFunction(fn) { this.questionUploadFunctions.push(fn); },
    isPositiveInteger(e) {
      const charCode = (typeof e.which === "number") ? e.which : e.keyCode,
        chr = String.fromCharCode(charCode);
      if(isNaN(parseInt(chr, 10))) e.preventDefault();
    },
    // clearForm() { this.$refs.form.reset(); this.questionNumber=0; this.Questions=[]},
    addQuestionsEnd() {
      for (let i=0; i<this.questionAddNumber; i++) {
        this.Questions.push({
          disc: this.questionAddType==='Discursiva',
          isCE: this.questionAddType==='Certo/Errado',
          numItems: this.questionAddNumberItems,
          keyId: this.currentKeyId,
        }); this.currentKeyId++;
      } this.questionNumber += this.questionAddNumber; this.$refs.addQForm.reset();
    },
    addQuestionsStart() {
      for (let i=0; i<this.questionAddNumber; i++) {
        this.Questions.unshift({
          disc: this.questionAddType==='Discursiva',
          isCE: this.questionAddType==='Certo/Errado',
          numItems: this.questionAddNumberItems,
          keyId: this.currentKeyId,
        }); this.currentKeyId++;
      } this.questionNumber += this.questionAddNumber; this.$refs.addQForm.reset();
    },
    RemoveQuestions() {
      if (this.questionRemoveOrder === 'Do final') {
        for (let i=0; i<this.questionRemoveNumber; i++) { this.Questions.pop(); this.questionUploadFunctions.pop(); }
      } else { for (let i=0; i<this.questionRemoveNumber; i++) { this.Questions.shift(); this.questionUploadFunctions.shift(); } }
      this.questionNumber -= this.questionRemoveNumber; this.$refs.removeQForm.reset();
    },
    getQuestionObject(obj) { this.questionObjects[obj.id] = obj.data; },
    async createExam() {
      if (this.isUploading) { return false } else { this.isUploading = true; }
      this.$store.commit("enableLoadingState");

      try {
        const uid = this.$store.state.auth.user.uid;
        for (let uploadFn of this.questionUploadFunctions) { uploadFn(uid); }
        await this.$store.dispatch('uploadNewSkillTest', {
          uname: this.$store.state.auth.user.displayName, uid: uid, questions: this.questionObjects
        });
        this.$router.push('/submissoes').catch(()=>{});
      }
      catch (e) { alert(`Ocorreu um erro! Entre em contato conosco: ${JSON.stringify(e)}`); this.isUploading = false; }
      finally { this.$store.commit('disableLoadingState'); }
    }
  }
}
</script>

<style scoped>
.ignored-button { opacity: 0.5; }
.ignored-button:hover { opacity: 1; }
.row-margin { margin-right: 5%; margin-left: 5%; }
.card-margin { text-align: center; text-align: -webkit-center; }
@media screen and (min-width: 1264px){
  .card-margin { padding-right: 10%; padding-left: 10%; }
}
</style>
